import * as React from "react";
import {useConnection, useWallet} from '@solana/wallet-adapter-react'
import axios from 'axios';
import {VersionedTransaction} from "@solana/web3.js";
import {TENSOR_CREATE_Q, TENSOR_DROP, TENSOR_EXECUTE_Q, TENSOR_GRAPHQL_URI, TENSOR_HEADERS} from "./tensor-defs";
import {useState} from "react";

const ClaimNft: React.FC = (props) => {
    const {connection} = useConnection();
    const {wallet, publicKey, signTransaction} = useWallet();

    const [isMinting, setMinting] = useState(false);
    const [mintingMessage, setMintingMessage] = useState("");
    const [mintedData, setMintedData] = useState([]);

    const resetMinting = () => {
        setMinting(false);
        setMintingMessage("");
    }

    const onClickCreateClaim = React.useCallback(async () => {
        if (publicKey == null) {
            alert('Wallet not connected');
            return
        }
        setMinting(true);
        // Query API for Solana tx
        setMintingMessage("Connecting with your wallet");
        const {data: createData} = await axios.post<
            | {
            data: {
                createClaim: number[];
            };
            errors: undefined;
        }
            | {
            data: undefined;
            errors: any[];
        }
        >(
            TENSOR_GRAPHQL_URI,
            {
                query: TENSOR_CREATE_Q,
                variables: {
                    recipientAddress: publicKey.toBase58(),
                    claimDropId: TENSOR_DROP,
                },
            },
            {
                headers: TENSOR_HEADERS,
            }
        );

        console.log(createData.data);

        if (!createData.data) {
            // Handle error
            console.error(createData.errors[0]['message']);
            alert(createData.errors[0]['message']);
            resetMinting();
            return;
        }

        // Solana tx for user to sign
        const tx = VersionedTransaction.deserialize(createData.data.createClaim);

        //console.log('versionned tx = ', tx);

        setMintingMessage("Please confirm transaction");

        const signedTx = await signTransaction(tx).then().catch(error => console.log(error));

        // If user closes wallet transaction
        if (typeof signedTx == "undefined") {
            resetMinting();
            return;
        }

        //console.log('signed Tx', signedTx);

        setMintingMessage("...Minting");

        // execute claim    
        const {data: executeData} = await axios.post<
            | {
            data: {
                executeClaim: {
                    claim: {
                        id: string;
                    };
                    nft: { address: string } | null;
                };
            };
            errors: undefined;
        }
            | {
            data: undefined;
            errors: any[];
        }
        >(
            TENSOR_GRAPHQL_URI,
            {
                query: TENSOR_EXECUTE_Q,
                variables: {
                    serializedMintTx: Array.from(signedTx.serialize()),
                },
            },
            {
                headers: TENSOR_HEADERS,
            }
        );

        if (executeData.errors) {
            console.error(executeData.errors[0]['message']);
            alert(executeData.errors[0]['message']);
            resetMinting();
            return;
        }

        console.log('executeData', executeData);

        const nftData = await executeData.data?.executeClaim.nft;
        if (nftData) {
            // NFT data immediately available: can show user
            console.log(nftData);
            setMintedData(nftData);
        } else {
            alert('no nft data, please retry');
            resetMinting();
        }
        resetMinting();
    }, [wallet, connection, publicKey, useWallet()]);


    // Sending new mint data to parent page
    React.useEffect(() => {
        if (mintedData.address) {
            props.claimNftCallback(mintedData);
        }
    }, [mintedData]);


    return (
        <>
            {isMinting
                ? (<>
                    <button className='button arrow mint'>{mintingMessage}</button>
                </>)
                : (<>
                    <button className='button arrow mint' onClick={onClickCreateClaim}>Mint for 700 SOL</button>
                </>)
            }
        </>
    )
};

export default ClaimNft;