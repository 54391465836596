// 1st DevNet DropID
//export const TENSOR_DROP = "e8b8bfe9-2d29-40c1-9b28-58c556209bec";
// new DevNet DropID
//export const TENSOR_DROP = "c3c4d9cb-6167-488c-8e55-b8b31bb7f9d6";
// MainNet
//export const TENSOR_DROP = "be29576a-f472-4201-84a3-31fac2460908";
// MainNet final 
export const TENSOR_DROP = "575b9b64-f464-45cc-babe-a292645b0df9";

export const TENSOR_AIR_DROPPED_DROP = "2589cbba-c988-4609-95e2-562945eb6f66";


export const TENSOR_HEADERS = {
  accept: "*/*",
  "content-type": "application/json",
//  Referer: "https://www.tensor.trade/",
};

export const TENSOR_GRAPHQL_URI = "https://creators-api.tensor.so/graphql";

export const TENSOR_GET_NFTS_Q = `
query GetClaimedNfts($recipientAddress: String, $dropId: String!) {
  getClaimedNfts(recipientAddress: $recipientAddress, dropId: $dropId) {
    address
    name
    metadataUri
    imageUri
    attributes
  }
}
`;

export const TENSOR_GET_AIRDROPPED_NFTS_Q = `
query AirdropAssets($pagination: PaginationParams!, $airdropId: String!) {
  airdropAssets(pagination: $pagination, airdropId: $airdropId) {
    assets {
      name
      image
      assetId
      attributes {
        traitType
        value
      }
      addresses
    }
    imagesBaseDir
  }
}
`

export const TENSOR_GET_NFT_Q = `
query AirdropAsset($address: String!) {
  airdropAsset(address: $address) {
    address
    name
    metadataUri
    imageUri
    attributes
    createdAt
    updatedAt
  }
}
`;

export const TENSOR_CREATE_Q = `
mutation CreateClaim(
  $recipientAddress: String!
  $claimDropId: String!
) {
  createClaim(
    recipientAddress: $recipientAddress
    claimDropId: $claimDropId
    phaseIndex: 0
    guardIndices: [0, 0]
  )
}
`;

export const TENSOR_EXECUTE_Q = `
mutation ExecuteClaim($serializedMintTx: [Int!]!) {
  executeClaim(serializedMintTx: $serializedMintTx) {
    claim {
      id
    }
    nft {
      address
      name
      imageUri
      metadataUri
      attributes
    }
  }
}
`;