import * as React from "react";
import {useEffect, useState, useRef} from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import ClaimNft from "../components/claim-nft";
import ShowClaimedNfts from "../components/show-claimed-nfts-static";
import Countdown from 'react-countdown';
import {Link, navigate} from "gatsby";
import Modal from 'react-modal';

import logo_k011 from "../images/k011_logo_black.svg";
import logo_tensor from "../images/logo_tensor.svg";
import logo_solana from "../images/logo_solana_color.svg";
import logo_dreamcult from "../images/logo_dreamcult.svg";
import BannerImage from "../images/banner_populus.jpg";
import loadingGif from "../images/loading.gif";
import close_modal from "../images/close_modal.svg"


const Populus: React.FC = () => {

    //26th Sep at 7pm CET
    //const [dropDate, setDropDate] = useState(1695747600000);
    const [dropDate, setDropDate] = useState(1695678800000);

    const [totalDrops, setTotalDrops] = useState(150);
    const [remainingPercentage, setRemainingPercentage] = useState(0);
    const [progress, setProgress] = useState(0);

    const [mintedData, setMintedData] = useState([]);

    const [isLive, setIsLive] = useState(false);
    const [isSoldOut, setIsSoldOut] = useState(true);

    // set to false when soldout
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        if (Date.now() >= dropDate) {
            setIsLive(true);
        }
    }, []);

    useEffect(() => {
        if ((progress) == totalDrops) {
            setIsSoldOut(true);
        }
        var remaining = (progress / totalDrops) * 100;
        setRemainingPercentage(remaining.toFixed(2));
    }, [progress]);

    const countdownRenderer = ({days, hours, minutes, seconds, completed}) => {
        if (completed) {
            setIsLive(true);
            if (showButton) {
                return <ClaimNft claimNftCallback={claimNftCountCallback}/>
            } else {
                return <button className='button black mint'><img alt="Loading" className="loading" src={loadingGif}/>
                </button>
            }

        } else {
            return <div className='button countdown'>
                <div>Live
                    in <span>{days}days</span><span>{hours}h</span>:<span>{minutes}m</span>:<span>{seconds}s</span>
                </div>
            </div>;
        }
    };

    const galleryCountCallback = (elementCount) => {
        //setProgress(elementCount);
        setProgress(150);
        setShowButton(true);
    }

    const claimNftCountCallback = (mintedData) => {
        setProgress(progress + 1);
        setMintedData(mintedData);
    }

    /* MODAL */
    const customStyles = {
        content: {top: '30%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', background: '#FFFFFF', zIndex: '999'},
        overlay: {zIndex: 1000, backgroundColor: 'rgba(0, 0, 0, 0.75)'}
    };

    Modal.setAppElement('#___gatsby');

    const [modalIsOpen, setModalIsOpen] = useState(true);

    function openModal() {
        setModalIsOpen(true);
    }

    function afterOpenModal() {
    }

    function closeModal() {
        setModalIsOpen(false);
        navigate(`/populus/${mintedData.address}`);
    }

    function closeModalBefore() {
        setModalIsOpen(false);
    }

    return (
        <Layout>
            <div className='container clearfix'>
                <section className='section-banner populus' style={{backgroundImage: `url(${BannerImage})`}}>
                    <div className='container clearfix flex'></div>
                </section>
            </div>
            <section className='section-title collection populus'>
                <Seo title="Playground - Populus Gallery View"/>
                <div className='container clearfix flex'>
                    <div className='col50 left info'>
                        <div className='clearfix tags'>
                            <Link to='/collections/' className='button black left'>Collection</Link>
                            <a className='button black left'>150 minted</a>
                        </div>
                        <h1>Populus <span>by _DreamCult_</span></h1>
                        <p className='big'>Populus imagines a future where humanity and technology have merged at the
                            physical level. Born out of the question ‘What if evolution and annihilation are the same
                            thing?’, Populus envisions a world where humanity has broken with its biological origins and
                            found new ways to augment and amplify its material capacities. In this ‘new order’, much as
                            in our current one, access to privilege and power is unequal, with unsettling ramifications
                            for society as a whole. As some individuals are elevated to godlike status by their
                            technological hybridization, others are condemned to madness, marginalisation, or
                            servitude.</p>
                        <p className='big'>Offering a strikingly fresh spin on the profile pic (or PfP), Populus
                            consists of 150 full-length character designs ranging from the ethereally beautiful to the
                            nightmarish. Each character is named with a fragment from Victorian or Edwardian poetry, and
                            assigned traits of varying rarity – including the social caste to which the character
                            belongs. The result is a diverse and startling collection of portraits of impossible beings
                            from an increasingly possible future - fascinating, sometimes alluring, often terrifying,
                            and utterly unique.</p>
                    </div>
                    <div className='col50 left mint-actions'>
                        <div className='mint-box'>
                            <div className='solana-icon'>
                                <h4>Secured By</h4>
                                <Link to='https://solana.com/' target='_blank'><img className='partner-logo solana'
                                                                                    src={logo_solana}/></Link>
                            </div>
                            <h2><span>price</span>7 SOL</h2>
                            <p>{remainingPercentage}% minted ({progress}/{totalDrops})</p>
                            <div className='progress-bar'>
                                <div className='progress' style={{width: `${remainingPercentage}%`}}></div>
                            </div>
                            {
                                isSoldOut
                                    ? (<button className='button black mint'>SOLDOUT</button>)
                                    : (<Countdown date={dropDate} renderer={countdownRenderer} />)
                            }

                        </div>
                        <div className='partners-logos clearfix'>
                            <div className='partner-item col33 left'>
                                <h4>Curated By</h4>
                                <Link to='https://www.k011.com/' target='_blank'><img className='partner-logo k011'
                                                                                      src={logo_k011}/></Link>
                            </div>
                            <div className='partner-item col33 left'>
                                <h4>Launched On</h4>
                                <Link to='https://www.tensor.trade/' target='_blank'><img
                                    className='partner-logo tensor' src={logo_tensor}/></Link>
                            </div>
                            <div className='partner-item col33 left'>
                                <h4>Created By</h4>
                                <Link to='https://www.dreamcult.io/' target='_blank'><img
                                    className='partner-logo dreamcult' src={logo_dreamcult}/></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {isLive &&
                <ShowClaimedNfts galleryCallback={galleryCountCallback}/>
            }
            {mintedData.address &&
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModalBefore}
                    style={customStyles}
                    portalClassName="minting-modal"
                    contentLabel="New Mint Modal"
                >
                    <div id="minting">
                        <a className='close-modal' onClick={closeModalBefore}><img src={close_modal}/></a>
                        <div className="minting-step-info">
                            <h3>Congratulations!</h3>
                            <p>You minted:</p><p className='big'>{mintedData.name}</p>
                            {/*<div className='minted-image'><img src={cdnPrefix+mintedData.imageUri} alt='rendering...' /></div>*/}
                            <button className='button black close' onClick={closeModal}>See your artwork!</button>
                        </div>
                    </div>
                </Modal>
            }
        </Layout>
    );
};

export default Populus;