import * as React from "react"
import {useEffect, useState} from "react";
import {useConnection, useWallet} from '@solana/wallet-adapter-react'

import {Link} from "gatsby";
import loadingGif from "../images/loading.gif";

const ShowClaimedNftsStatic: React.FC = (props) => {

    const {connection} = useConnection();
    const {wallet, publicKey, signTransaction} = useWallet();
    const [claimedNftsCount, setClaimedNftsCount] = useState();
    const [claimedNfts, setClaimedNfts] = useState([]);
    const [showGallery, setShowGallery] = useState(false);
    const [showFilters, setShowFilters] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const cdnPrefix = "https://prod-image-cdn.tensor.trade/images/slug=playground-populus/400x400/freeze=false/";

    const callGetClaimedNfts = React.useCallback(async () => {

        setShowGallery(true);
        setIsLoading(true);

        fetch("/populus/populus.json")
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Set the artwork items to state
                setClaimedNfts(data);
                setIsLoading(false);
                setClaimedNftsCount(data.length);
            })
            .catch(error => {
                console.error('There has been a problem with fetching the data:', error);
            });

    }, [wallet, connection, publicKey, useWallet()]);


    useEffect(() => {
        callGetClaimedNfts();
    }, []);

    useEffect(() => {
    }, [claimedNfts]);

    useEffect(() => {
        if (claimedNftsCount >= 0) {
            props.galleryCallback(claimedNftsCount);
        }
    }, [claimedNftsCount]);


    const PaginatedPopulus = ({data, RenderComponent, pageLimit, dataLimit}) => {
        const [currentPage, setCurrentPage] = useState(1);
        let pops = data;
        //let [pages] = useState(Math.round(pops.length / dataLimit));
        let [pages] = useState(Math.ceil(pops.length / dataLimit));

        function changePage(event) {
            const pageNumber = Number(event.target.textContent);
            setCurrentPage(pageNumber);
            const filterSection = document.getElementById('filters');
            filterSection.scrollIntoView({behavior: 'smooth'});

        }

        const getPaginatedData = () => {
            const startIndex = currentPage * dataLimit - dataLimit;
            const endIndex = startIndex + dataLimit;
            return pops.slice(startIndex, endIndex);
        };

        const getPaginationGroup = () => {
            let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
            return new Array(pages).fill().map((_, idx) => start + idx + 1);
        };

        return (
            <>
                <div className='clearfix' id='filters'></div>

                <div>

                    <div className='collections-list'>
                        {getPaginatedData().map((d, idx) => (
                            <RenderComponent key={idx} data={d}/>
                        ))}
                    </div>

                    <div className='pagination'>

                        {/* show page numbers */}
                        {getPaginationGroup().map((item, index) => (
                            <div className={`button ${currentPage === item ? "black" : ""}`}
                                 active={(currentPage === item).toString()}
                                 key={index}
                                 onClick={changePage}
                                 pagenum={item}
                            >
                                <span>{item}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    }

    const IndividualPopulus = ({data}) => {
        let liveUrl = "";
        let mobilePicUrl = "";
        let address = null;
        let airPrefix = 'https://prod-image-cdn.tensor.trade/images/slug=playground-populus/400x400/freeze=false/https://prod-tensor-creators-s3.s3.us-east-1.amazonaws.com/drop-metadata/d53f1ed3-f57d-48e1-a6dc-2326361296d3/images/';

        address = data.onchainId;

        liveUrl = '/populus/' + address;
        if (mobilePicUrl == "") {
            mobilePicUrl = cdnPrefix + data.imageUri;
        }

        return (
            <div className='col30 left collection-item populus'>
                <Link className='image' to={liveUrl}
                        style={{backgroundImage: `url(${mobilePicUrl})`}}></Link>
                <div className='details'>
                    <h4>{data.name}</h4>
                    <Link className='button black detailed-view' to={`/populus/${address}`}>View</Link>
                </div>
            </div>
        )
    }


    return (
        <section className='collection-detail'>
            <div className='container clearfix'>

                {showGallery
                    ?
                    claimedNftsCount > 0
                        ? (
                            //<RenderGallery/>
                            <PaginatedPopulus
                                data={claimedNfts}
                                RenderComponent={IndividualPopulus}
                                pageLimit={10}
                                dataLimit={48}
                            />
                        )
                        : ( isLoading
                            ? (<><img alt="Loading" className="loading" src={loadingGif}/></>)
                            : (<></>)
                        )
                    : (<><a className='button arrow transparent' onClick={callGetClaimedNfts}>Show Already Minted Pieces</a></>)
                }

            </div>
        </section>
    );
};


export default ShowClaimedNftsStatic;